<template>
  <v-container v-if="username !== ''">
    <div>
      <hr>
      <h1 style="text-align: center">Flight Info</h1>
      <hr>
    </div>

    <div style="padding: 1rem;" v-if="flight !== null && airports !== null">
      <v-row style="align-items: center;">
        <div style="text-align: center" class="origDst">
          <h1>{{flight['origin']}}</h1>
          <p v-if="airports[flight['origin']] !== null" style="margin-bottom: 0">{{airports[flight['origin']]['name']}}</p>
          <p>RWY {{flight['origin_rwy']}}</p>
        </div>
        <div style="flex: 1 1 0;">
          <h4 v-if="flightIsActive" style="text-align: center">Distance: {{distanceCovered.toFixed(2)}} / {{totalDistance.toFixed(2)}}nm</h4>
          <h4 v-else style="text-align: center">Total Distance {{totalDistance.toFixed(2)}}nm</h4>
          <v-progress-linear :value="percentProgress" striped height="10" style="width: auto; margin-left: 5vw; margin-right: 5vw"></v-progress-linear>
          <h4  v-if="flightIsActive" style="text-align: center; margin-top: .5rem;">ETE {{estimatedTimeRemaining}}</h4>
          <h4 v-else  style="text-align: center; margin-top: .5rem;">Flight Time {{msToTime(flight['in_flight_length'])}}</h4>
        </div>
        <div style="text-align: center" class="origDst">
          <h1>{{flight['destination']}}</h1>
          <p v-if="airports[flight['destination']] !== null" style="margin-bottom: 0">{{airports[flight['destination']]['name']}}</p>
          <p>RWY {{flight['destination_rwy']}}</p>
        </div>
      </v-row>
    </div>

    <template v-if="flight !== null && flightData !== null && !aircraftLoading" style="height: 70vh">
      <v-row>
        <l-map :zoom.sync="zoom" :center="liveMapCenter"  :maxBoundsViscosity="1.0" :maxBounds="maxBounds" style="height: 70vh; z-index: 0" :options="{worldCopyJump:true, gestureHandling: true}" gestureHandling ref="map">
          <l-tile-layer :url="url" :attribution="attribution" :noWrap="false"/>
          <l-tile-layer v-if="weatherMapURL != null" :url="weatherMapURL" layer-type="overlay" :visible="showWeather"/>
          <l-polyline :lat-lngs="routeLines" color="green"></l-polyline>
          <l-circle-marker v-for="(latLng, index) in routeLines" :lat-lng="latLng" :radius="4" color="red">
            <l-tooltip>
              {{routeItemLabels[index]}}
            </l-tooltip>
          </l-circle-marker>

          <template v-if="flightIsActive">
            <WebsocketData :url-input="[websocketURL]" :data.sync="websocketData">
              <template slot-scope="{outData}" v-if="outData != null && websocketURL in outData && Object.keys(outData[websocketURL]).length > 0">
                  <v-rotated-marker :zIndexOffset="20000" style="z-index: 3" :lat-lng="latLong(outData[websocketURL]['latitude'], outData[websocketURL]['longitude'])" :icon="icon" :rotationAngle="parseInt(radians_to_degrees(outData[websocketURL]['heading']).toFixed(2))">
                  </v-rotated-marker>
              </template>
            </WebsocketData>
          </template>

          <l-polyline :lat-lngs="flownPath"></l-polyline>

          <l-control>
            <v-checkbox class="ma-0" label="Show Weather" v-model="showWeather" style="background-color: #484848; padding: .3rem; border-radius: .3rem" dense hide-details/>
          </l-control>
        </l-map>
      </v-row>
      <v-row>
        <v-col md="8" cols="12">
          <FlightModalGraphTwoAxes :flight-data="flightData" :stream="websocketData[websocketURL]" :filter-field="['ground_speed', 'altitude']" :labels="['Ground Speed (Kts)', 'Altitude (ft)']" title="Ground Speed & Altitude" titleType="h1"/>
        </v-col>
        <v-col md="4" cols="12" style="margin-top:1rem">
          <hr>
          <h2>Aircraft Information</h2>
          <hr>
          <template v-if="aircraft !== null">
            <div class="data-row">
              <p class="alignedItems">
                <span>Tail Number:</span>
                <span>{{aircraft['aircraft']['registration']}}</span>
              </p>
            </div>
            <div class="data-row">
              <p class="alignedItems">
                <span>Airframe:</span>
                <span>{{aircraft['airframe']['name']}}</span>
              </p>
            </div>
            <div class="data-row">
              <p class="alignedItems">
                <span>Airframe ICAO:</span>
                <span>{{aircraft['airframe']['icao']}}</span>
              </p>
            </div>
            <div class="data-row">
              <p class="alignedItems">
                <span>Airline:</span>
                <span>{{aircraft['airline']['name']}}</span>
              </p>
            </div>
            <div class="data-row">
              <p class="alignedItems">
                <span>Callsign:</span>
                <span>{{flight['callsign']}}</span>
              </p>
            </div>
            <div class="data-row">
              <p class="alignedItems">
                <span>Flight Number:</span>
                <span>{{flight['flight_number']}}</span>
              </p>
            </div>
          </template>
          <template v-else>
            <div class="d-flex fill-height align-center justify-center">
              <h2>Data Missing</h2>
            </div>
          </template>
        </v-col>
      </v-row>
    </template>
    <template v-else style="height: 70vh;">
      <v-row  style="height: 70vh;">
        <v-col style="text-align: center; align-items: center; justify-content: center; display: flex; flex-direction: column">
          <v-progress-circular
            :size="150"
            :width="5"
            color="amber"
            indeterminate
          ></v-progress-circular>
          <h1>
            Loading...
          </h1>
        </v-col>
      </v-row>
    </template>

    <template v-for="(landingDataItem, index) in landingData">
      <div style="margin-top: 1rem" >
        <hr>
        <h1 style="text-align: center">Approach Data</h1>
        <hr>
      </div>

      <v-row>
        <v-col md="6" cols="12">
          <FlightModalGraph :flight-data="{'flight_data_arr': landingDataItem['approach']}" filter-field="cross_wind" title="Cross Wind" titleType="h1"/>
        </v-col>
        <v-col md="6" cols="12">
          <FlightModalGraph :flight-data="{'flight_data_arr': landingDataItem['approach']}" filter-field="head_wind" title="Tail Wind" titleType="h1"/>
        </v-col>
      </v-row>

      <div style="margin-top: 1rem" >
        <hr>
        <h1 style="text-align: center">Landing Data</h1>
        <hr>
      </div>

      <v-row>
        <v-col cols="12" md="6">
          <l-map :zoom="15" :center="landingLocations[index]" style="z-index: 0; height:30rem;" ref="landingMap" :options="{worldCopyJump: true}">
            <l-tile-layer :url="satelliteURL" :attribution="attribution"></l-tile-layer>
            <l-circle-marker v-if="thresholdMarker != null" :lat-lng="thresholdMarker" :radius="2" :color="'blue'"></l-circle-marker>
            <l-circle-marker :lat-lng="landingLocations[index]" :radius="2" :color="'red'"></l-circle-marker>
          </l-map>
        </v-col>

        <v-col cols="12" md="6">
          <template v-if="runwayData !== null">
            <v-divider/>
            <h4>Runway Data</h4>
            <v-divider/>

            <div class="data-row">
              <p class="alignedItems">
                <span>Runway:</span>
                <span>{{runwayData['runway_identifier']}}</span>
              </p>
              <p  class="alignedItems">
                <span>Elevation:</span>
                <span>{{runwayData['elevation']}} ft</span>
              </p>
            </div>

            <div class="data-row">
              <p class="alignedItems">
                <span>Length:</span>
                <span>{{runwayData['length']}} ft</span>
              </p>
              <p class="alignedItems">
                <span>Width:</span>
                <span>{{runwayData['width']}} ft</span>
              </p>
            </div>

            <div class="data-row">
              <p class="alignedItems">
                <span>Heading:</span>
                <span>{{runwayData['magnetic_bearing']}} &#176;</span>
              </p>
              <p class="alignedItems">
                <span>Slope:</span>
                <span>{{runwayData['gradient']}} &#176;</span>
              </p>
            </div>
          </template>

          <v-divider/>
          <h4>Landing Data</h4>
          <v-divider/>

          <div class="data-row">
            <p class="alignedItems">
              <span>Heading:</span>
              <span>{{radians_to_degrees(landingDataItem['landing']['report']['heading_mag']).toFixed(2)}} &#176;</span>
            </p>
            <p class="alignedItems">
              <span>Bank:</span>
              <span>{{radians_to_degrees(landingDataItem['landing']['report']['bank'] * -1).toFixed(2)}} &#176;</span>
            </p>
          </div>
          <div class="data-row">
            <p class="alignedItems">
              <span>Pitch:</span>
              <span>{{radians_to_degrees(landingDataItem['landing']['report']['pitch'] * -1).toFixed(2)}} &#176;</span>
            </p>
            <p class="alignedItems">
              <span>Speed:</span>
              <span>{{landingDataItem['landing']['report']['ground_speed'].toFixed(0)}} Kts</span>
            </p>
          </div>
          <div class="data-row">
            <p class="alignedItems">
            <span>
              Vertical Speed:
            </span>
            <span v-if="'vertical_speed_touchdown_value' in landingDataItem['landing']['report']">
              -{{ (landingDataItem['landing']['report']['vertical_speed_touchdown_value'] * 60).toFixed(0)}} fpm
            </span>
            <span v-else>
              {{ (landingDataItem['landing']['report']['vertical_speed_aircraft'] * 60).toFixed(0)}} fpm
            </span>
            </p>
            <p class="alignedItems">
              <span>G Force:</span>
              <span>{{landingDataItem['landing']['report']['g_force'].toFixed(2)}} G</span>
            </p>
          </div>

          <div class="data-row" v-if="'cross_wind' in landingDataItem['landing']['report'] && 'head_wind' in landingDataItem['landing']['report']">
            <p class="alignedItems">
            <span>
              Crosswind:
            </span>
              <span>
              {{ (landingDataItem['landing']['report']['cross_wind']).toFixed(2)}} knots
            </span>
            </p>
            <p class="alignedItems">
              <span>Tailwind:</span>
              <span>
              {{landingDataItem['landing']['report']['head_wind'].toFixed(2)}} knots
            </span>
            </p>
          </div>
        </v-col>
      </v-row>
      <div style="">
        <hr>
      </div>
    </template>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {
  LCircleMarker,
  LControlAttribution,
  LIcon,
  LMap,
  LMarker,
  LPolyline,
  LFeatureGroup,
  LPopup,
  LTileLayer,
  LTooltip,
  LControl
} from "vue2-leaflet";
import L from "leaflet";
import Shared from "@/components/Shared";
import WebsocketData from "../components/WebsocketData";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import airplaneIcon from "@/assets/icons/airplane.png";
import FlightModalGraph from "../components/FlightModalGraph";
import FlightModalGraphTwoAxes from "../components/FlightModalGraphTwoAxes";


export default {
  name: "FlightShare",
  components: {
    FlightModalGraph,
    FlightModalGraphTwoAxes,
    LMap,
    LTileLayer,
    LMarker,
    LCircleMarker,
    LPopup,
    LTooltip,
    LFeatureGroup,
    LControlAttribution,
    LIcon,
    LPolyline,
    LControl,
    WebsocketData,
    'v-rotated-marker': Vue2LeafletRotatedMarker
  },
  props: ['flightID'],
  created: function(){
    this.createdFlow()
    this.updateWeatherTimestamp()
  },
  mounted: function(){

  },
  data: function(){
    return {
      flightData: null,
      flight: null,
      activeFlight: null,
      OFP: null,

      zoom: 5,
      liveMapCenter: L.latLng(0, 0),
      maxBounds: [[-90, Number.NEGATIVE_INFINITY], [90, Number.POSITIVE_INFINITY]],

      url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      satelliteURL:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      prefix: "",
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',

      flownPath: [],
      websocketData: {},
      landingData: [],
      runwayData: null,
      runwayDataLoading: true,
      drawnItems: null,

      airports: null,

      aircraftLoading: true,
      aircraft: null,

      icon: L.icon({
        iconUrl: airplaneIcon,
        iconSize: [36, 36],
        iconAnchor: [18, 18]
      }),

      username: "",
      activeFlightTimer: null,
      thresholdMarker: null,

      showWeather: true,
      weatherUpdateTimer: null,
      latestWeatherTS: 0,

      mapCenterLiveData: false,
    }
  },
  watch: {
    '$route.params.flightID': function (id) {
      this.$router.go()
    },
    showWeather: function(val){
      if (val){
        this.weatherUpdateTimer = setInterval(this.updateWeatherTimestamp, 10000)
      }else{
        clearInterval(this.weatherUpdateTimer)
      }
    },
    websocketData: function (val) {
      if (val != null && Object.keys(val).length > 0) {
        let webData = val[this.websocketURL]
        this.flownPath.push([webData['latitude'], webData['longitude']])

        if (!this.mapCenterLiveData){
          this.mapCenterLiveData = true
          this.liveMapCenter = this.latLong(webData['latitude'], webData['longitude'])
        }

        if (this.flightData !== null && 'flight_data_arr' in this.flightData) {
          this.flightData['flight_data_arr'].push(webData)
          this.flightData = Object.assign({}, this.flightData)
        }
      }
    },
    mapCriteria: function(val){
      if (val){
        setTimeout(() => {
          this.$refs.map.mapObject.fitBounds(this.drawnItems.getBounds(), {padding: [20,20]})
        }, 100)
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    weatherMapURL: function(){
      if (this.latestWeatherTS === 0){
        return null
      }
      return 'https://tilecache.rainviewer.com/v2/radar/'+ this.latestWeatherTS +'/512/{z}/{x}/{y}/6/1_1.png'
    },
    totalDistance: function(){
      if (this.flightPlan != null) {
        return Shared.methods.calcDistance(this.flightPlan['origin_lat'], this.flightPlan['origin_long'], this.flightPlan['destination_lat'], this.flightPlan['destination_long'])
      }
      return 0
    },
    lastPosition: function(){
      if (this.websocketData != null && Object.keys(this.websocketData).length > 0){
        const lastData = this.websocketData[this.websocketURL]
        return [lastData['latitude'], lastData['longitude']]
      }
      return null
    },
    remainingDistance: function(){
      if (this.flightPlan != null){
        let destination = [this.flightPlan['destination_lat'], this.flightPlan['destination_long']]
        let lastPosition = this.lastPosition
        if (lastPosition !== null){
          return Shared.methods.calcDistance(lastPosition[0], lastPosition[1], destination[0], destination[1])
        }
      }
      return 0
    },
    distanceCovered: function(){
      if (this.flightPlan != null){
        return Math.max(this.totalDistance - this.remainingDistance,0)
      }
      return 0
    },
    percentProgress: function(){
      if (!this.flightIsActive){
        return 100
      }
      return ((this.totalDistance - this.remainingDistance) / this.totalDistance) * 100
    },
    estimatedTimeRemaining: function(){
      if (this.websocketData != null && Object.keys(this.websocketData).length > 0) {
        return Shared.methods.formatHoursToTime(this.remainingDistance / this.websocketData[this.websocketURL]['ground_speed'])
      }
      return 0
    },
    flightIsActive: function (){
      return this.activeFlight != null && this.flight != null && this.activeFlight['id'] === this.flight['id'];
    },
    routeLines: function () {
      const route = this.generateFullRoutePlan(this.flightPlan, this.flight)
      if (route === null){
        return []
      }
      return route.map(item => L.latLng(item['lat'], item['long']))
    },
    routeItemLabels: function (){
      const route = this.generateFullRoutePlan(this.flightPlan, this.flight)
      if (route === null){
        return []
      }
      return route.map(item => item['name'])
    },
    websocketURL: function(){
      return this.username + "/singleClient"
    },
    landingLocations: function(){
      if (this.landingData.length === 0){
        return [this.latLong(0,0)]
      }
      return this.landingData.map(d => this.latLong(d['landing']['report']['latitude'], d['landing']['report']['longitude']))
    },
    mapCriteria: function(){
      return this.flight !== null && this.flightData !== null && !this.aircraftLoading
    }
  },
  methods: {
    createdFlow: function(){
      this.getUsernameFromFlightID(this.flightID).then(data => {
        this.username = data['result']['username']
        this.getFlightData(this.flightID)
      })
    },
    getUsernameFromFlightID: function(flightID){
      return fetch(this.$baseURL+"/getUsernameFromFlightID?flightID="+flightID)
        .then(response => response.json())
    },
    getFlightData: function(flightID){
      this.loadActiveFlight(flightID)
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      }
      fetch(this.$baseURL+"/"+this.username+"/userFlightData?flightID="+flightID, requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(data => {
              this.flightData = Object.assign({}, data)

              if (data !== null && 'flight_data_arr' in data) {
                const latLngs = data['flight_data_arr'].map(run => [run['latitude'], run['longitude']])
                this.flownPath.unshift(...latLngs)

                if (this.liveMapCenter.lat.valueOf() === 0 && this.liveMapCenter.lng.valueOf() === 0){
                  if (this.flightIsActive){
                    this.liveMapCenter = this.latLong(data['flight_data_arr'][0]['latitude'], data['flight_data_arr'][0]['longitude'])
                  }else{

                    let smallestLat = null
                    let largestLat = null
                    let smallestLon = null
                    let largestLon = null

                    for (let i = 0; i < data['flight_data_arr'].length; i++){
                      let ref = data['flight_data_arr'][i]
                      const lat = ref['latitude']
                      const lon = ref['longitude']

                      if (smallestLat === null || lat < smallestLat){
                        smallestLat = lat
                      }
                      if (largestLat === null || lat > largestLat){
                        largestLat = lat
                      }

                      if (smallestLon === null || lon < smallestLon){
                        smallestLon = lon
                      }
                      if (largestLon === null || lon > largestLon){
                        largestLon = lon
                      }
                    }

                    this.liveMapCenter = L.latLng((smallestLat + largestLat)/2, (smallestLon + largestLon)/2)
                    let polylineLayer = L.polyline(this.flownPath)
                    this.drawnItems = new L.FeatureGroup()
                    this.drawnItems.addLayer(polylineLayer)
                  }
                }

              }
            })
          }
        })
      fetch(this.$baseURL+"/"+this.username+"/getSingleFlight?flightID="+flightID, requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(result => {
              this.flight = Object.assign({},result)
              if (this.flight.freeflight) {
                this.runwayDataLoading = false
              }else{
                fetch(this.$baseURL + "/runway?airport=" + this.flight['destination'] + "&runway=RW" + this.flight['destination_rwy'])
                  .then(response => {
                    if (response.ok){
                      response.json().then(data => {
                        const result = data
                        if (result !== null){
                          this.thresholdMarker = L.latLng(result['latitude'], result['longitude'])
                        }
                        this.runwayData = result
                      })
                    }
                    this.runwayDataLoading = false
                  })
                fetch(this.$baseURL + "/airport?airport=" + this.flight['destination'] + "," + this.flight['origin'])
                  .then(response => {
                    if (response.ok){
                      return response.json().then(data => {
                        this.airports = data
                      })
                    }
                  })
              }

              fetch(this.$baseURL + "/getAircraft?aircraftID="+this.flight['aircraft_id'])
                .then(response => {
                  if (response.ok){
                    response.json().then(data => {
                      if (data !== null ) {
                        this.aircraft = data
                      }
                      this.aircraftLoading = false
                    })
                  }else{
                    this.aircraftLoading = false
                  }
                })
            })
          }
        })
      fetch(this.$baseURL + "/getFlightPlan?flightID="+flightID + "&username="+this.username, requestOptions)
        .then(response => response.json())
        .then(function (data){
          if (data['result'] !== null && data['error'] === "") {
            this.flightPlan = data['result']
          }
        }.bind(this))
    },
    loadActiveFlight: function(flightID){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      }

      fetch(this.$baseURL + "/landingData?flightID="+flightID)
        .then(response => response.json())
        .then(function (data){
          console.log(data)
          if (data['result'] != null) {
            this.landingData = data['result']
          }
        }.bind(this))

      fetch(this.$baseURL+"/"+this.username+"/activeFlight", requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(data => {
              this.activeFlight = Object.assign({}, data)
              if (this.activeFlightTimer === null){
                // If activeFlightTimer not yet running setup timer to re-check activeFlight every 30 secs
                this.activeFlightTimer = setInterval(function() { this.loadActiveFlight(flightID) }.bind(this), 30000);
              }

              if (!this.flightIsActive && this.flight !== null){
                clearInterval(this.activeFlightTimer)
              }
            })
          }
          console.error(response.status)
        })
    },
    generateFullRoutePlan: function(flightPlan, flight){
      let route = []

      if (flightPlan === null || flightPlan === undefined || flight === null || flight.freeflight){
        return route
      }

      route.push({
        name: flightPlan['origin'] + "/" + flightPlan['origin_rwy'],
        lat: flightPlan['origin_lat'],
        long: flightPlan['origin_long']
      })

      for (let i = 0; i < flightPlan['waypoints'].length; i++){
        const waypoint = flightPlan['waypoints'][i]
        route.push({
          name: waypoint['label'],
          lat: waypoint['lat'],
          long: waypoint['long']
        })
      }

      route.push({
        name: flightPlan['destination'] + "/" + flightPlan['destination_rwy'],
        lat: flightPlan['destination_lat'],
        long: flightPlan['destination_long']
      })

      return route
    }.bind(this),
    radians_to_degrees: function(radians)
    {
      let pi = Math.PI;
      return radians * (180/pi);
    },
    latLong: function(lat, long){
      return L.latLng(lat, long)
    },
    msToHours: function(msIn){
      return Shared.methods.msToHours(msIn)
    },
    msToTime: function(duration) {
      return Shared.methods.msToTime(duration)
    },
    updateWeatherTimestamp: function(){
      fetch("https://tilecache.rainviewer.com/api/maps.json")
        .then(response => response.json())
        .then(data =>{
          this.latestWeatherTS = data[data.length - 1]
        })
    }
  }
}
</script>

<style scoped>
  .data-row{
    padding-left:15px;
    padding-right: 15px;
    margin-left:-15px;
    margin-right:-12px;
    display: flex;
  }

  .data-row p{
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    margin-left:15px;
    margin-right: 15px;
  }

  .alignedItems{
    margin-bottom: 0 !important;
  }

  .alignedItems span:first-child{
    text-align: left;
  }

  .alignedItems span:last-child{
    text-align: right;
    flex-grow: 1;
  }

  #line-chart{
    width:100%;
  }

  .origDst{
    width: 15%;
  }

  .origDst p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<script>
  import { Line, mixins } from 'vue-chartjs'
  const {reactiveProp} = mixins

  export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['options'],
    mounted () {
      this.renderChart(this.chartData, this.options)
    },
    methods: {
      update () {
        this.$data._chart.update()
      }
    },
    watch: {
      chartData: function(){
        this.update()
      }, deep:true
    }
  }
</script>

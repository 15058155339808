<template>
  <div :is="tag">
    <slot :outData="this.outData"></slot>
  </div>
</template>


<script>
import {mapGetters} from "vuex";

let baseURL = ''
let webSocketBaseURL = ''
if (process.env.NODE_ENV === 'production'){
  baseURL = new URL(window.location).origin
  webSocketBaseURL = "wss://" + new URL(window.location).host
}else{
  baseURL = 'http://127.0.0.1:8080'
  webSocketBaseURL = 'ws://127.0.0.1:8080'
}

export default {
  name: "WebsocketData",
  props: {
    path: String,
    vatsim: Boolean,
    tag: {
      type: String,
      default: 'div'
    },
    avoidLogin: {
      type: Boolean,
      default: false
    },
    urlInput: Array,
    enableDisable: {
      type: Array,
      default: null
    },
    requireUserSend: {
      type: Array,
      default: null
    },
    data: {
      type: Object,
      default: null
    }
  },
  data: function (){
    return{
      websocketData: {},
      outData: {},
      enableDisablePrev: []
    }
  },
  created: function () {
    for(let i = 0; i < this.urlInput.length; i++){
      const websocketURL = this.urlInput[i]
      this.websocketData[websocketURL] = {
        connection: null,
        connectionRetryTimer: null,
        connectionClosed: false,
        data: null,
        dataPath: websocketURL,
        setupFunction: null
      }

      if (this.requireUserSend !== null && this.requireUserSend[i]){
        let parent = this
        this.websocketData[websocketURL]['setupFunction'] = function (connection){
          connection.send(JSON.stringify(parent.currentUser))
        }
      }

      if (this.enableDisable === null || this.enableDisable[i]){
        this.initiateWebSocket(this.websocketData[websocketURL])
      }
    }

    window.onbeforeunload = () => {
      this.closeAllConnections()
    }

    this.enableDisablePrev = this.enableDisable
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      isLoggedIn: 'isLoggedIn'
    })
  },
  watch: {
    outData: {
      handler: function (val) {
        this.$emit("update:data", Object.assign({}, val))
      },
      deep:true
    },
    enableDisable: function (val){
      for (let i = 0; i < this.urlInput.length; i++){
        const websocketURL = this.urlInput[i]
        if (val[i] && !this.enableDisablePrev[i]){
          this.initiateWebSocket(this.websocketData[websocketURL])
          console.log("Initiate: " + websocketURL)
        }else if(!val[i] && this.enableDisablePrev[i]){
          this.closeConnection(this.websocketData[websocketURL])
          console.log("Close: " + websocketURL)
          this.outData[websocketURL] = null
        }
      }
      this.enableDisablePrev = val
    }
  },
  methods: {
    initiateWebSocket: function(websocket){
      let parent = this;
      websocket.connection = new WebSocket(webSocketBaseURL + "/" + websocket.dataPath)
      websocket.connection.onmessage = function (event){
        let receivedData = JSON.parse(event.data)
        // Don't save data if there is an error but allow through if null
        if (receivedData == null || !('error' in receivedData)){
          websocket.data = receivedData

          parent.$set(parent.outData, websocket.dataPath, receivedData)
        }else{
        }
      }.bind(this)

      websocket.connection.onopen = function (event){
        if (websocket.hasOwnProperty("setupFunction") && websocket.setupFunction != null){
          websocket.setupFunction(websocket.connection)
        }
        console.log("Connection opened")
      }.bind(this)

      websocket.connection.onclose = function (event) {
        if (!websocket.connectionClosed) {
          console.log("Retrying connection in 5 seconds")
          websocket.connectionRetryTimer = setTimeout(function (){
            this.initiateWebSocket(websocket)
          }.bind(this), 5000)
        }else{
          console.log("CLOSE Clean")
          websocket.connectionClosed = false
        }
      }.bind(this)

      websocket.connection.onerror = function(event){
        console.log("Connection error")
      }.bind(this)
    },
    closeConnection: function(connection){
      console.log("Close Connection")
      if (connection.connection != null){
        clearTimeout(connection.connectionRetryTimer)
        connection.connectionClosed = true
        connection.connection.close(1000)
        connection.connection = null
      }
    },
    closeAllConnections: function(){
      for(let i = 0; i < this.urlInput.length; i++){
        const websocketURL = this.urlInput[i]
        this.closeConnection(this.websocketData[websocketURL])
      }
      console.log("Before Destroy");
    }
  },
  beforeDestroy() {
    this.closeAllConnections()
  },
}
</script>

<style scoped>

</style>

<template>

</template>

<script>
export default {
name: "Shared",
  methods: {
    generateFullRoute: function (flightPlan){
      let route = []

      if (flightPlan === null){
        return route
      }

      route.push({
        name: flightPlan['origin']['icao_code'] + "/" + flightPlan['origin']['plan_rwy'],
        lat: flightPlan['origin']['pos_lat'],
        long: flightPlan['origin']['pos_long']
      })

      if (flightPlan['nav_log']['fix'] != null) {
        for (let i = 0; i < flightPlan['nav_log']['fix'].length; i++) {
          const fix = flightPlan['nav_log']['fix'][i]
          route.push({
            name: fix['name'],
            ident: fix['ident'],
            lat: fix['pos_lat'],
            long: fix['pos_long']
          })
        }
      }

      route.push({
        name: flightPlan['destination']['icao_code'] + "/" + flightPlan['destination']['plan_rwy'],
        lat: flightPlan['destination']['pos_lat'],
        long: flightPlan['destination']['pos_long']
      })

      return route
    },
    generateFullRoutePlan: function(flightPlan, flight){
      let route = []

      if (flightPlan === null || flight === null || flight.freeflight){
        return route
      }

      route.push({
        name: flightPlan['origin'] + "/" + flightPlan['origin_rwy'],
        lat: flightPlan['origin_lat'],
        long: flightPlan['origin_long']
      })

      for (let i = 0; i < flightPlan['waypoints'].length; i++){
        const waypoint = flightPlan['waypoints'][i]
        route.push({
          name: waypoint['label'],
          lat: waypoint['lat'],
          long: waypoint['long']
        })
      }

      route.push({
        name: flightPlan['destination'] + "/" + flightPlan['destination_rwy'],
        lat: flightPlan['destination_lat'],
        long: flightPlan['destination_long']
      })

      return route
    },
    msToTime: function(duration) {
      if (duration === 0){
        return "UNKNOWN"
      }

      let seconds = duration / 1000000000
      let h = Math.floor(seconds / 3600);
      let m = Math.floor(seconds % 3600 / 60);
      let s = Math.floor(seconds % 3600 % 60);

      if (h <= 9) {
        h = "0" + h
      }
      if (m <= 9) {
        m = "0" + m
      }
      if (s <= 9) {
        s = "0" + s
      }
      return h + ":" + m + ":" + s;
    },
    msToHours: function(duration){
      if (duration === 0){
        return 0
      }

      let seconds = duration / 1000000000;
      return (seconds / 3600).toFixed(1)
    },
    radians_to_degrees: function(radians)
    {
      let pi = Math.PI;
      return radians * (180/pi);
    },
    calcDistance: function (lat1, lon1, lat2, lon2){

      function toRad(Value)
      {
        return Value * Math.PI / 180;
      }

      const R = 3440; // nautical miles
      const dLat = toRad(lat2-lat1);
      const dLon = toRad(lon2-lon1);
      lat1 = toRad(lat1);
      lat2 = toRad(lat2);

      const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      return R * c;
    },
    midPoint: function (lat1, lon1, lat2, lon2) {

      function toRad(Value) {
        return Value * Math.PI / 180;
      }

      function toDegrees(value){
        return (value * (180 / Math.PI))
      }

      lat1 = toRad(lat1)
      lon1 = toRad(lon1)
      lat2 = toRad(lat2)
      lon2 = toRad(lon2)

      const Bx = Math.cos(lat2) * Math.cos(lon2-lon1)
      const By = Math.cos(lat2) * Math.sin(lon2-lon1)

      const latMid = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt( (Math.cos(lat1) + Bx) * (Math.cos(lat1)+Bx) + By * By ))
      const lonMid = lon1 + Math.atan2(By, Math.cos(lat1) + Bx)

      return [toDegrees(latMid), toDegrees(lonMid)]
    },
    formatMinutesToTime: function(totalMins){
      let mins = totalMins % 60
      let hours = (totalMins - mins)/60

      mins = Math.floor(mins)
      hours = Math.floor(hours)

      if (mins <= 9){
        mins = "0" + mins
      }

      if (hours <= 9){
        hours = "0" + hours
      }

      return hours + ":" + mins
    },
    formatHoursToTime: function(hoursIn){

      if (hoursIn === Number.POSITIVE_INFINITY){
        return "Unknown"
      }

      let totalMins = hoursIn * 60
      return this.formatMinutesToTime(totalMins)
    },
    requiredFieldRule: function(element){
      return [() => !!element || 'This field is required']
    },
    calcETA: function(airports, originICAO, destinationICAO){
      const origin = airports.filter(d => d.identifier === originICAO)[0]
      const destination = airports.filter(d => d.identifier === destinationICAO)[0]

      // Using very rough estimate of 435 knots + 15 minutes for landing represented as .25
      const distance = this.calcDistance(origin['airport_ref_latitude'], origin['airport_ref_longitude'], destination['airport_ref_latitude'], destination['airport_ref_longitude'])
      return this.formatHoursToTime(distance / 435 + 0.25)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div style="margin-top:1em; margin-bottom: 1em; width: 100%">
    <v-row style="margin-bottom: 1em;">
      <p :is="titleType">{{ title }}</p>
    </v-row>
    <v-row >
      <LineChart ref="lineChart" :chart-data="chartData" :options="chartOptions" :styles="myStyles" />
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import LineChart from "@/components/LineChart";

export default {
  name: "FlightModalGraphTwoAxes",
  props: ['flightData', 'stream', 'filterField', 'title', 'titleType', 'labels'],
  components: {LineChart},
  data: function(){
    return {
      chartData: {},
      chartOptions: {
        legend: {
          display: true,
        },
        scales:{
          xAxes : [{
            type: 'time',
            ticks: {
              autoSkip: true,
            }
          }],
          yAxes: [{
            id: 'A',
            type: 'linear',
            position: 'left',
            scaleLabel: {
              display: true,
              labelString: this.labels[0],
              beginAtZero: true,
            },
          }, {
            id: 'B',
            type: 'linear',
            position: 'right',
            scaleLabel: {
              display: true,
              labelString: this.labels[1],
              beginAtZero: true,
            },
          }]
        },
        elements:{
          point:{
            radius: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        hover: {
          mode: null
        }
      }
    }
  },
  computed: {
    myStyles: function (){
      return {
        height: '15rem',
        width: '100%',
      }
    },
  },
  mounted: function(){
    if (this.chartData.datasets.length === 0){
      this.chartData = this.generateChartData()
    }
  },
  // This seems a little cumbersome ideally we'd run generateChartData for the whole thing in a computed component,
  // however, running maps on that amount of data is expensive RAM wise. This is much cheaper: Initial load and then
  // add data with stream
  watch: {
    stream: function(val){
      const chartDataIn = this.chartData
      if (val !== null) {
        chartDataIn.labels.push(moment.utc(new Date(val['collection_time'])).format('YYYY-MM-DD HH:mm:ss'))
        for (let i = 0; i < this.filterField.length; i++) {
          const filterField = this.filterField[i]
          chartDataIn.datasets[i].data.push(val[filterField])
        }
      this.chartData = Object.assign({}, chartDataIn)
      }

    }
  },
  methods: {
    generateChartData: function(){
      let retChartData = {labels:[], datasets:[{label: this.labels[0], yAxisID: 'A', borderColor: '#f87979', fill:false, data:[]}, {label: this.labels[1], yAxisID: 'B', borderColor: '#FFFFFF', fill:false, data: []}]}
      if (this.flightData === null || !('flight_data_arr' in this.flightData) || this.flightData['flight_data_arr'] === null){
        return retChartData
      }

      retChartData.labels = this.flightData["flight_data_arr"].map(run => moment.utc(new Date(run['collection_time'])).format('YYYY-MM-DD HH:mm:ss'))

      for (let i  = 0; i < this.filterField.length; i++){
        const filterField = this.filterField[i]
        retChartData.datasets[i].data = this.flightData["flight_data_arr"].map(run => run[filterField])
      }

      return retChartData
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div style="margin-top:1em; margin-bottom: 1em; width: 100%">
    <v-row style="margin-bottom: 1em;">
      <p :is="titleType">{{ title }}</p>
    </v-row>
    <v-row >
      <LineChart ref="lineChart" :chart-data="generateChartData" :options="chartOptions" :styles="myStyles" />
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import LineChart from "@/components/LineChart";
export default {
  name: "FlightModalGraph",
  props: ['flightData', 'filterField', 'title', 'titleType'],
  components: {LineChart},
  data: function(){
    return {
      chartOptions: {
        legend: {
          display: false,
        },
        scales:{
          xAxes : [{
            type: 'time',
            ticks: {
              autoSkip: true,
            }
          }],
        },
        elements:{
          point:{
            radius: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        hover: {
          mode: null
        }
      }
    }
  },
  computed: {
    myStyles: function (){
      return {
        height: '15rem',
        width: '100%',
      }
    },
    generateChartData: function(){
      let retChartData = {labels:[], datasets:[{label: "x", borderColor: '#f87979', fill:false}]}
      if (this.flightData === null || !('flight_data_arr' in this.flightData)){
        return retChartData
      }

      const flightDataI = this.flightData["flight_data_arr"]
      const names = flightDataI.map(run => moment(new Date(run['collection_time'])).format('YYYY-MM-DD HH:mm:ss'))
      const data = flightDataI.map(run => run[this.filterField])

      retChartData.labels = names
      retChartData.datasets[0].data = data
      return retChartData
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
